<template>
  <div class="agriculture">
    <div class="desc box_padding">
      <h3>行业难题</h3>
      <p>农业部规范农药产品标签上都必须标注所规定的溯源二维码。</p>
      <p>市场假冒农药、化肥、种子严重农户深受其害。</p>
      <div class="desc_img"></div>
      <p>包装粗糙，环境粉尘大，风吹日晒表面无法贴标和喷码，容易磨损破坏。</p>
      <p>经销窜货严重，货品沉重，扫描登记困难。</p>
    </div>
    <div class="desc_2 box_padding">
      <h3>解决方案</h3>
      <h3></h3>
      <div class="desc_img"></div>
      <p>针对性一物一码赋码方案保护二维码信息，抗破坏性强。</p>
      <p>丰富实施经验保障产品全流程溯源采集操作简便，数据准确。</p>
      <p>产品溯源信息全程可追溯，提高产品溯源品质管理。</p>
      <p>防伪验证清晰直观，农户快速辨别产品真伪。</p>
      <img src="~@/assets/img/product/agriculture_info.png" />
      <p>
        以产品唯一码身份管理技术为核心，基于SAAS云平台，利用大数据、物联网、移动互联网技术，通过数字码、二维码、RFID等标签为载体，以防伪保真为切入点，竭诚为企业提供产品信息全生命周期管理服务，包括软件应用服务、综合解决方案服务和平台运营服务，实现产品从采购、生产、加工、仓储、流通及销售等环节的全程追溯。不仅为企业产品安全保驾护航，更为企业实现信息化战略，开展数字化管理与移动营销，提供了强有力的支持。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Agriculture",
};
</script>

<style lang="less" scoped>
.agriculture {
  .box_padding {
    padding: 50px 30px;
  }

  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    font-size: 35px;
    color: #333;
    margin: 20px 0;
    text-align: justify;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }
  img {
    width: 100%;
    border-radius: 10px;
    height: 400px;
    border: 2px solid #f0f0f0;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/agriculture_1.jpg") no-repeat
      right/cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  .desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      height: 250px;
      background: url("~@/assets/img/banner/agriculture.png") no-repeat
        center/cover;
    }
  }
}
</style>